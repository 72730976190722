<template>
  <b-card title="Detail Dealer">
    <b-col cols="12">
      <b-form-group
          label="Dealer Code"
          description="Dealer Code"
        >
          <b-form-input
            v-model="dealer.dealerCode"
            type="text"
            placeholder="Enter code"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Name"
          description="Dealer Name"
        >
          <b-form-input
            v-model="dealer.dealerName"
            type="text"
            placeholder="Enter name"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Postal"
          description="Postal"
        >
          <b-form-input
            v-model="dealer.postalCode"
            type="text"
            placeholder="Enter Postal"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Billing Address 1"
          description="Dealer Billing Address"
        >
          <b-form-input
            v-model="dealer.billingAddress1"
            type="text"
            placeholder="Enter Address"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Billing Address 2"
          description="Dealer Billing Address"
        >
          <b-form-input
            v-model="dealer.billingAddress2"
            type="text"
            placeholder="Enter Address"
            disabled
            required
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Email Address"
          description="Dealer Email Address"
        >
          <b-form-input
            v-model="dealer.dealerEmailAddress"
            type="text"
            placeholder="Enter Address"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Phone"
          description="Phone"
        >
          <b-form-input
            v-model="dealer.phone"
            type="text"
            placeholder="Enter Phone"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Credit Exposure"
          description="Credit Exposure"
        >
          <b-form-input
            v-model="dealer.creditExposure"
            type="text"
            placeholder="Credit Exposure"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Total Receiveable"
          description="Total Receiveable"
        >
          <b-form-input
            v-model="dealer.totalReceivables"
            type="text"
            placeholder="Total Receiveable"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Sales Values"
          description="Sales Values"
        >
          <b-form-input
            v-model="dealer.salesValues"
            type="text"
            placeholder="Sales Values"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Overdue Amount"
          description="Overdue Amount"
        >
          <b-form-input
            v-model="dealer.overdueAmount"
            type="text"
            placeholder="Overdue Amount"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Overdue Days"
          description="Overdue Days"
        >
          <b-form-input
            v-model="dealer.overdueDays"
            type="text"
            placeholder="Overdue Days"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Industry Type"
          description="Industry Type"
        >
          <b-form-input
            v-model="dealer.industryType"
            type="text"
            placeholder="Industry Type"
            required
            disabled
          ></b-form-input>
      </b-form-group>
      <b-form-group
          label="Second Industry Type"
          description="Second Industry Type"
        >
          <b-form-input
            v-model="dealer.secondIndustryType"
            type="text"
            placeholder="Second Industry Type"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Payment Term"
          description="Payment Term"
        >
          <b-form-input
            v-model="dealer.paymentTerms"
            type="text"
            placeholder="Payment Term"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Status"
          description="Status"
        >
          <b-form-input
            v-model="dealerStatus"
            type="text"
            placeholder="Status"
            required
            disabled
          ></b-form-input>
      </b-form-group>

    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      dealer: {
        customerName: '',
        billingAddress1: '',
        billingAddress2: '',
        dealerCode: '',
        creditLimit: '',
        creditExposure: '',
        salesValues:'',
      }
    }
  },
  mounted() {
    this.detailDealer(this.$route.params.idDealer).then((data) => {
      this.dealer = data
      this.dealer.totalReceivables =  parseFloat(data.totalReceivables.$numberDecimal);
      this.dealer.salesValues = parseFloat(data.salesValues.$numberDecimal);
      this.dealer.creditExposure = parseFloat(data.creditExposure.$numberDecimal);
      this.dealer.overdueAmount = parseFloat(data.overdueAmount.$numberDecimal);
    }).catch((e) => {

    })
  },
  methods: {
    ...mapActions({
      detailDealer: 'dealer/detailDealer'
    }),
  },
  computed: {
    dealerStatus: {
      get() {
        if (this.dealer.status !== undefined) {
          return this.dealer.status === '' ? 'Active' : 'Inactive'
        }

        return 'Inactive';
      },
      set(value) {
      }
    },
    
    
  }
}
</script>
